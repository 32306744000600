<template>
  <div class="about__body">
    <div class="about-banner"></div>
    <div class="body__column--box">
      <div class="container__box about_width ptb35">
        <div class="page__content--box">
          <div  class="page__content--section about-text" style="float: left;">
            <h3 class="page__content--title">{{$t('blog.buy.title')}}</h3>
            <div v-html="$t('blog.buy.content')"></div>
          </div>
          <div class="page_content_img" style="float: right;"><img src="../assets/images/about/maifang.png"></div>
        </div>
      </div>
    </div>
    <div class="body__column--box">
      <div class="container__box about_width ptb35">
        <div class="page__content--box">
          <div class="page_content_img" style="float: left;"><img src="../assets/images/about/maifang2.png"></div>
          <div class="page__content--section about-text">
            <h3 class="page__content--title">{{$t('blog.sale.title')}}</h3>
            <div v-html="$t('blog.sale.content')"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="body__column--box">
      <div class="container__box about_width ptb35">
        <div class="page__content--box">
          <div class="page__content--section about-text" style="float: left;">
            <h3 class="page__content--title">{{$t('blog.tg.title')}}</h3>
            <div v-html="$t('blog.tg.content')"></div>
          </div>
          <div class="page_content_img" style="float: right;"><img src="../assets/images/about/tuoguan.png"></div>
        </div>
      </div>
    </div>
    <div class="body__column--box">
      <div class="container__box about_width" style="padding-bottom: 100px;">
        <div class="page__content--box">        
          <div class="page_content_img" style="float: left;"><img src="../assets/images/about/zul.png"></div>
          <div class="page__content--section about-text">
            <h3 class="page__content--title">{{$t('blog.rent.title')}}</h3>  
            <div v-html="$t('blog.rent.content')"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "About",
  data(){
    return {
      contact:{
        userName:"",
        mobile:"",
        email:"",
        weixinNo:"",
        question:""
      },
      submitting:false
    }
  },
  created() {

  },
  methods:{
    async simulateSubmit(){
      this.submitting = true;
      let res = await this.$api.requestSendTouch(this.contact);
      this.submitting = false;
      if(code !== 1000){
        return false;
      }
      let initialData = this.$options.data();
      let contact = initialData.contact;
      this.contact = contact;
      this.$q.notify({
        message:this.$t("about.contact.SendSuccess"),
        position:"top"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .about__body{
    background-color: #fff;
  }
  .page_content_img{
    vertical-align: top;
    margin-top: 42px;
    img{
      width: 420px;
      height:320px;
      background-size: cover;
    }
  }
  .about_width{
    width: 1020px;
  }
  #app .page__content--box .about-text{
    width: 440px;
  }
  .about-banner{
    background: url('../assets/images/about/banner.jpg') no-repeat center top;
    height: 600px;
    background-size: cover;
    width: 96%;
    margin: 0 auto;
    border-radius: 50px;
  }
</style>
