<template>
  <div class="calculator__body">
    <div class="body__column--box mt10">
      <div class="container__box w950 ptb50">
        <div class="calWrap">
          <div class="cal_top topjianbian">
            <div class="cal_nav displayflex">
              <div
                class="cal_changenav flexli"
                :class="{
                  'current':dkcaltypeitem.value === dkcaltype.value,
                  'outer_bj':dkcaltypeitem.value===2,
                  'outer_bx':dkcaltypeitem.value===1
                }"
                v-for="dkcaltypeitem in dkcaltype.list"
                @click="dkcaltypeClick(dkcaltypeitem.value)"
                :data-name="dkcaltypeitem.name"
              >{{dkcaltypeitem.label}}</div>
            </div>
            <div class="cal_result displayflex">
              <div class="cal_resultli flexli">
                <div class="cal_title">{{$t('cankaoyuegong')}}</div>
                <div class="cal_price cal_price_hook">{{replay.yuegong}}</div>
                <div class="cal_hktotal">还款总额(元)
                  <span class="htotalnum">{{replay.totalPrice}}万</span>
                </div>
              </div>
              <div class="cal_resultli flexli">
                <div class="cal_title cal_dijian">{{dkcaltype.value === 2 ? '每月递减(元)' : ''}}</div>
                <div class="cal_price cal_dijianprice">{{dkcaltype.value === 2 ? replay.yuegongdijian : ''}}</div>
                <div class="cal_hktotal cal_right">支付利息(元)
                  <span class="htotallixinum">{{replay.totalLixi}}万</span>
                </div>
              </div>
            </div>
            <div class="cal_benxiinfo">
              <a class="cal_bxfont" id="typethml" href="#">等额本息还款详情</a>
              <div class="cal_bxicon"></div>
            </div>
          </div>

          <div class="cal_form">
            <div class="cal_li border_bottom">
              <div class="cal_leftname">贷款类型</div>
              <div class="cal_rightselect">
                <select v-model="dktype.value" id="lanChange" class="lanchange daikuanchange">
                  <option v-for="type in dktype.list" :value="type.value">{{type.label}}</option>
                </select>
                <div class="rightrow"></div>
              </div>
            </div>
            <div class="cal_box" v-if="dktype.value === 2 || dktype.value === 3">
              <div class="cal_li border_bottom gjj_hook">
                <div class="cal_leftname dkname" data-zuhe="公积金贷款金额">贷款金额</div>
                <div class="cal_rightselect">
                  <input v-model="gjj.value" type="tel" placeholder="请输入金额" maxlength="7" onkeyup="if(isNaN(value))execCommand('undo')" onafterpaste="if(isNaN(value))execCommand('undo')"
                         class="lannum gjjf" />
                  <span>万</span>
                </div>
              </div>
              <div class="cal_li border_bottom gjj_hook">
                <div class="cal_leftname">贷款年限</div>
                <div class="cal_rightselect">
                  <select v-model="gjj.yearValue" id="gjjlanyear" class="lanchange yearchange">
                    <option v-for="year in gjj.year" :value="year.year">{{year.year}}年</option>
                  </select>
                  <div class="rightrow"></div>
                </div>
              </div>
              <div class="cal_li gjj_hook">
                <div class="cal_leftname">公积金利率</div>
                <div class="cal_rightselect">
                  <select v-model="gjj.lilvValue" id="gjjlanlilv" class="lanchange lilvchange">
                    <option v-for="gjj in gjjlilv" :value="gjj.lilv">{{gjj.name}}年</option>
                  </select>
                  <div class="rightrow"></div>
                </div>
              </div>
            </div>
            <div class="cal_box" v-if="dktype.value === 1 || dktype.value === 3">
              <div class="cal_li border_bottom  shangdai_hook sdfund">
                <div class="cal_leftname dkname" data-zuhe="商业贷款金额">贷款金额</div>
                <div class="cal_rightselect">
                  <input type="tel" v-model="sd.value" placeholder="请输入金额" maxlength="7"
                         onkeyup="if(isNaN(value))execCommand('undo')"
                         onafterpaste="if(isNaN(value))execCommand('undo')"
                         class="lannum shangyef"
                  />
                  <span>万</span>
                </div>
              </div>
              <div class="cal_li border_bottom shangdai_hook">
                <div class="cal_leftname">贷款年限</div>
                <div class="cal_rightselect">
                  <select v-model="sd.yearValue" id="sylanyear" class="lanchange yearchange">
                    <option v-for="year in sd.year" :value="year.year">{{year.year}}年</option>
                  </select>
                  <div class="rightrow"></div>
                </div>
              </div>
              <div class="cal_li shangdai_hook">
                <div class="cal_leftname">商贷利率</div>
                <div class="cal_rightselect">
<!--                  <select v-model="sd.lilvValue" id="shangyelanlilv" class="lanchange lilvchange">-->
<!--                    <option v-for="sdlvItem in sdlilv" :value="sdlvItem.lilv">{{sdlvItem.name}}</option>-->
<!--                  </select>-->
<!--                  <div class="rightrow"></div>-->
                  <input type="tel" v-model="sd.lilvValue" placeholder="请输入商贷利率"
                         onkeyup="if(isNaN(value))execCommand('undo')"
                         onafterpaste="if(isNaN(value))execCommand('undo')"
                         class="lannum shangyef"
                  />%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Calculator",
  data(){
    return {
      dkcaltype:{
        value:1,
        list:[
          {label:"等额本息",value:1,name:"benxi"},
          {label:"等额本金",value:2,name:"benjin"}
        ]
      },
      dktype:{
        value:1,
        list:[
          {label:"商业贷款",value:1}
        ],
      },
      sd:{
        label:"商贷",
        value:"",
        lilvValue:"",
        yearValue:20,
        year:[
          {
            year: 1,
            select: false
          },
          {
            year: 2,
            select: false
          },
          {
            year: 3,
            select: false
          },
          {
            year: 4,
            select: false
          },
          {
            year: 5,
            select: false
          },
          {
            year: 6,
            select: false
          },
          {
            year: 7,
            select: false
          },
          {
            year: 8,
            select: false
          },
          {
            year: 9,
            select: false
          },
          {
            year: 10,
            select: false
          },
          {
            year: 11,
            select: false
          },
          {
            year: 12,
            select: false
          },
          {
            year: 13,
            select: false
          },
          {
            year: 14,
            select: false
          },
          {
            year: 15,
            select: false
          },
          {
            year: 16,
            select: false
          },
          {
            year: 17,
            select: false
          },
          {
            year: 18,
            select: false
          },
          {
            year: 19,
            select: false
          },
          {
            year: 20,
            select: true
          },
          {
            year: 21,
            select: false
          },
          {
            year: 22,
            select: false
          },
          {
            year: 23,
            select: false
          },
          {
            year: 24,
            select: false
          },
          {
            year: 25,
            select: false
          },
          {
            year: 26,
            select: false
          },
          {
            year: 27,
            select: false
          },
          {
            year: 28,
            select: false
          },
          {
            year: 29,
            select: false
          },
          {
            year: 30,
            select: false
          }
        ],
        lv:[
          {
            year: 1,
            lilv: "0.0435"
          },
          {
            year: 2,
            lilv: "0.0475"
          },
          {
            year: 6,
            lilv: "0.049"
          }
        ],
        lilv:[
          {
            lilv: 1,
            name: "基准利率"
          },
          {
            lilv: 1.1,
            name: "1.1倍利率"
          },
          {
            lilv: 1.15,
            name: "1.15倍利率"
          },
          {
            lilv: 1.2,
            name: "1.2倍利率"
          },
          {
            lilv: 1.3,
            name: "1.3倍利率"
          },
          {
            lilv: 0.9,
            name: "9折利率"
          },
          {
            lilv: 0.85,
            name: "85折利率"
          },
          {
            lilv: 0.8,
            name: "8折利率"
          },
          {
            lilv: 0.7,
            name: "7折利率"
          }
        ]
      },
      gjj:{
        label:"公积金",
        value:"",
        lilvValue:"",
        yearValue:20,
        year:[
          {
            year: 1,
            select: false
          },
          {
            year: 2,
            select: false
          },
          {
            year: 3,
            select: false
          },
          {
            year: 4,
            select: false
          },
          {
            year: 5,
            select: false
          },
          {
            year: 6,
            select: false
          },
          {
            year: 7,
            select: false
          },
          {
            year: 8,
            select: false
          },
          {
            year: 9,
            select: false
          },
          {
            year: 10,
            select: false
          },
          {
            year: 11,
            select: false
          },
          {
            year: 12,
            select: false
          },
          {
            year: 13,
            select: false
          },
          {
            year: 14,
            select: false
          },
          {
            year: 15,
            select: false
          },
          {
            year: 16,
            select: false
          },
          {
            year: 17,
            select: false
          },
          {
            year: 18,
            select: false
          },
          {
            year: 19,
            select: false
          },
          {
            year: 20,
            select: true
          },
          {
            year: 21,
            select: false
          },
          {
            year: 22,
            select: false
          },
          {
            year: 23,
            select: false
          },
          {
            year: 24,
            select: false
          },
          {
            year: 25,
            select: false
          },
          {
            year: 26,
            select: false
          },
          {
            year: 27,
            select: false
          },
          {
            year: 28,
            select: false
          },
          {
            year: 29,
            select: false
          },
          {
            year: 30,
            select: false
          }
        ],
        lv:[
          {
            year: 1,
            lilv: "0.0275"
          },
          {
            year: 6,
            lilv: "0.0325"
          }
        ],
        lilv:[
          {
            lilv: 1,
            name: "基准利率"
          },
          {
            lilv: 1.1,
            name: "1.1倍利率"
          },
          {
            lilv: 1.15,
            name: "1.15倍利率"
          },
          {
            lilv: 1.2,
            name: "1.2倍利率"
          },
          {
            lilv: 1.3,
            name: "1.3倍利率"
          },
          {
            lilv: 0.9,
            name: "9折利率"
          },
          {
            lilv: 0.85,
            name: "85折利率"
          },
          {
            lilv: 0.8,
            name: "8折利率"
          },
          {
            lilv: 0.7,
            name: "7折利率"
          }
        ]
      }
    }
  },
  created() {
    this.dkcaltypeClick(this.dkcaltype.value)
  },
  computed:{
    replay(){
      let dkcaltype = this.dkcaltype.value;
      let dktype = this.dktype.value;
      let num,year,lv;
      let sdnum,sdyear,sdlv,gjjnum,gjjyear,gjjlv;
      let data = {
        yuegong:0,
        year:0,
        totalPrice:0,
        totalLixi:0,
        totalDknum:0,
        monthdataArray:[]
      };
      if(dktype === 1){
        num = this.sd.value;
        year = this.sd.yearValue;
        lv = this.sd.lilvValue / 100;
      }else if(dktype === 2){
        num = this.gjj.value;
        year = this.gjj.yearValue;
        lv = this.gjj.lilvValue;
      }else if(dktype === 3){
        sdnum = this.sd.value;
        sdyear = this.sd.yearValue;
        sdlv = this.sd.lilvValue;
        gjjnum = this.gjj.value;
        gjjyear = this.gjj.yearValue;
        gjjlv = this.gjj.lilvValue;
      }
      if(dkcaltype === 1){
        data = this.benxi(num,year,lv)
      }else if(dkcaltype === 2){
        data = this.benjin(num,year,lv)
      }

      if(dktype === 3){
        data = this.zuhe(dkcaltype, sdnum, gjjnum, sdyear, gjjyear, sdlv, gjjlv);
      }

      return data
    },
    sdlilv(){
      let lvList = this.lilvCal(this.sd.yearValue, this.sd.lv, this.sd.lilv);
      if(!this.sd.lilvValue){
        this.sd.lilvValue = lvList[0].lilv
      }
      return lvList;
    },
    gjjlilv(){
      let lvList = this.lilvCal(this.gjj.yearValue, this.gjj.lv, this.gjj.lilv);
      if(!this.gjj.lilvValue){
        this.gjj.lilvValue = lvList[0].lilv
      }
      return lvList;
    }
  },
  methods:{
    dkcaltypeClick(value){
      this.dkcaltype.value = value;
    },
    //商贷-公积金贷款统一函数
    singleDk: function (type, num, year, lilv) {
      var _this = this;
      // type:1等额本息 2等额本金，num 贷款金额 year贷款年限，lilv：贷款基准利率
      if (type == 1) {
        return _this.benxi(type, num, year, lilv)
      } else if (type == 2) {
        return _this.benjin(type, num, year, lilv)
      }
    },
    //组合贷款计算
    zuhe: function (type, sdnum, gjjnum, sdyear, gjjyear, sdlilv, gjjlilv) {
      var _this = this,
        year = sdyear > gjjyear ? sdyear : gjjyear;
      if (type == 1) {
        var sdObj = _this.benxi(sdnum, sdyear, sdlilv);
        var gjjObj = _this.benxi(gjjnum, gjjyear, gjjlilv);

        if (sdObj.monthdataArray.length > gjjObj.monthdataArray.length) {
          var mergemonthdataArray = sdObj.monthdataArray.map(function (item, index) {
            if (index < gjjObj.monthdataArray.length) {
              return {
                monthName: item.monthName,
                yuelixi: item.yuelixi + gjjObj.monthdataArray[index].yuelixi,
                yuebenjin: item.yuebenjin + gjjObj.monthdataArray[index].yuebenjin,
                leftFund: item.leftFund + gjjObj.monthdataArray[index].leftFund
              }
            } else {
              return {
                monthName: item.monthName,
                yuelixi: item.yuelixi,
                yuebenjin: item.yuebenjin,
                leftFund: item.leftFund
              }
            }

          })
        } else {
          var mergemonthdataArray = gjjObj.monthdataArray.map(function (item, index) {
            if (index < sdObj.monthdataArray.length) {
              return {
                monthName: item.monthName,
                yuelixi: item.yuelixi + sdObj.monthdataArray[index].yuelixi,
                yuebenjin: item.yuebenjin + sdObj.monthdataArray[index].yuebenjin,
                leftFund: item.leftFund + sdObj.monthdataArray[index].leftFund
              }
            } else {
              return {
                monthName: item.monthName,
                yuelixi: item.yuelixi,
                yuebenjin: item.yuebenjin,
                leftFund: item.leftFund
              }
            }
          })
        }
        return {
          yuegong: this.formatCurrency(sdObj.yuegong * 1 + gjjObj.yuegong * 1),
          totalLixi: this.formatCurrency(sdObj.totalLixi * 1 + gjjObj.totalLixi * 1),
          totalPrice: this.formatCurrency(sdObj.totalPrice * 1 + gjjObj.totalPrice * 1),
          monthdataArray: mergemonthdataArray,
          totalDknum: parseFloat(sdObj.totalDknum) + parseFloat(gjjObj.totalDknum),
          year: year
        }

      } else if (type == 2) {
        var sdObj = _this.benjin(sdnum, sdyear, sdlilv);
        var gjjObj = _this.benjin(gjjnum, gjjyear, gjjlilv);
        if (sdObj.monthdataArray.length > gjjObj.monthdataArray.length) {
          var mergemonthdataArray = sdObj.monthdataArray.map(function (item, index) {
            if (index < gjjObj.monthdataArray.length) {
              return {
                monthName: item.monthName,
                yuelixi: item.yuelixi + gjjObj.monthdataArray[index].yuelixi,
                yuebenjin: item.yuebenjin + gjjObj.monthdataArray[index].yuebenjin,
                leftFund: item.leftFund + gjjObj.monthdataArray[index].leftFund
              }
            } else {
              return {
                monthName: item.monthName,
                yuelixi: item.yuelixi,
                yuebenjin: item.yuebenjin,
                leftFund: item.leftFund
              }
            }

          })
        } else {
          var mergemonthdataArray = gjjObj.monthdataArray.map(function (item, index) {
            if (index < sdObj.monthdataArray.length) {
              return {
                monthName: item.monthName,
                yuelixi: item.yuelixi + sdObj.monthdataArray[index].yuelixi,
                yuebenjin: item.yuebenjin + sdObj.monthdataArray[index].yuebenjin,
                leftFund: item.leftFund + sdObj.monthdataArray[index].leftFund
              }
            } else {
              return {
                monthName: item.monthName,
                yuelixi: item.yuelixi,
                yuebenjin: item.yuebenjin,
                leftFund: item.leftFund
              }
            }
          })
        }
        return {
          yuegong: this.formatCurrency(sdObj.yuegong * 1 + gjjObj.yuegong * 1),
          totalLixi: this.formatCurrency(sdObj.totalLixi * 1 + gjjObj.totalLixi * 1),
          totalPrice: this.formatCurrency(sdObj.totalPrice * 1 + gjjObj.totalPrice * 1),
          yuegongdijian: this.formatCurrency(sdObj.yuegongdijian * 1 + gjjObj.yuegongdijian * 1),
          totalDknum: parseFloat(sdObj.totalDknum) + parseFloat(gjjObj.totalDknum),
          year: year,
          monthdataArray: mergemonthdataArray
        }
      }

    },
    //等额本息计算
    benxi: function ( num, year, lilv) {
      //每月月供额=〔贷款本金×月利率×(1＋月利率)＾还款月数〕÷〔(1＋月利率)＾还款月数-1〕
      var month = parseInt(year) * 12,
        monthlilv = parseFloat(lilv) / 12,
        dknum = parseFloat(num) * 10000;

      //每月月供
      var yuegong = (dknum * monthlilv * Math.pow((1 + monthlilv), month)) / (Math.pow((1 + monthlilv), month) - 1);

      //总利息=还款月数×每月月供额-贷款本金
      var totalLixi = month * yuegong - dknum;
      //还款总额 总利息+贷款本金
      var totalPrice = totalLixi + dknum,
        leftFund = totalLixi + dknum;

      //循环月份
      var monthdataArray = [],
        nowmonth = new Date().getMonth(),
        realmonth = 0;

      for (var i = 1; i <= month; i++) {
        realmonth = nowmonth + i;
        var yearlist = Math.floor(i / 12);

        realmonth = realmonth - 12 * yearlist;

        if (realmonth > 12) {
          realmonth = realmonth - 12
        }
        //console.log(realmonth)
        //每月应还利息=贷款本金×月利率×〔(1+月利率)^还款月数-(1+月利率)^(还款月序号-1)〕÷〔(1+月利率)^还款月数-1〕
        var yuelixi = dknum * monthlilv * (Math.pow((1 + monthlilv), month) - Math.pow((1 + monthlilv), i - 1)) / (Math.pow((1 + monthlilv), month) - 1);
        //每月应还本金=贷款本金×月利率×(1+月利率)^(还款月序号-1)÷〔(1+月利率)^还款月数-1〕
        var yuebenjin = dknum * monthlilv * Math.pow((1 + monthlilv), i - 1) / (Math.pow((1 + monthlilv), month) - 1);
        leftFund = leftFund - (yuelixi + yuebenjin);
        if (leftFund < 0) {
          leftFund = 0
        }
        monthdataArray[i - 1] = {
          monthName: realmonth + "月",
          yuelixi: yuelixi,
          yuebenjin: yuebenjin,
          //剩余还款
          leftFund: leftFund
        }
      }
      return {
        yuegong: this.formatCurrency(yuegong),
        totalLixi: this.formatCurrency(totalLixi / 10000),
        totalPrice: this.formatCurrency(totalPrice / 10000),
        monthdataArray: monthdataArray,
        totalDknum: this.formatCurrency(num / 10000),
        year: year
      }
    },
    //等额本金计算
    benjin: function (num, year, lilv) {
      var month = parseInt(year) * 12,
        monthlilv = parseFloat(lilv) / 12,
        dknum = parseFloat(num) * 10000,
        yhbenjin = 0; //首月还款已还本金金额是0
      //每月应还本金=贷款本金÷还款月数
      var everymonthyh = dknum / month
      //每月月供额=(贷款本金÷还款月数)+(贷款本金-已归还本金累计额)×月利率
      var yuegong = everymonthyh + (dknum - yhbenjin) * monthlilv;
      //每月月供递减额=每月应还本金×月利率=贷款本金÷还款月数×月利率
      var yuegongdijian = everymonthyh * monthlilv;
      //总利息=〔(总贷款额÷还款月数+总贷款额×月利率)+总贷款额÷还款月数×(1+月利率)〕÷2×还款月数-总贷款额
      var totalLixi = ((everymonthyh + dknum * monthlilv) + dknum / month * (1 + monthlilv)) / 2 * month - dknum;
      //还款总额 总利息+贷款本金
      var totalPrice = totalLixi + dknum,
        leftFund = totalLixi + dknum;

      //循环月份
      var monthdataArray = [],
        nowmonth = new Date().getMonth(),
        realmonth = 0;

      for (var i = 1; i <= month; i++) {
        realmonth = nowmonth + i;
        var yearlist = Math.floor(i / 12);

        realmonth = realmonth - 12 * yearlist;

        if (realmonth > 12) {
          realmonth = realmonth - 12
        }
        yhbenjin = everymonthyh * (i - 1);
        var yuebenjin = everymonthyh + (dknum - yhbenjin) * monthlilv;
        //每月应还利息=剩余本金×月利率=(贷款本金-已归还本金累计额)×月利率
        var yuelixi = (dknum - yhbenjin) * monthlilv;
        leftFund = leftFund - yuebenjin;
        if (leftFund < 0) {
          leftFund = 0
        }
        monthdataArray[i - 1] = {
          monthName: realmonth + "月",
          yuelixi: yuelixi,
          //每月本金
          yuebenjin: everymonthyh,
          //剩余还款
          leftFund: leftFund
        }
      }

      return {
        yuegong: this.formatCurrency(yuegong),
        totalLixi: this.formatCurrency(totalLixi / 10000),
        totalPrice: this.formatCurrency(totalPrice / 10000),
        monthdataArray: monthdataArray,
        totalDknum: this.formatCurrency(num / 10000),
        year: year,
        yuegongdijian: this.formatCurrency(yuegongdijian)
      }

    },
    //将数值四舍五入(保留2位小数)后格式化成金额形式
    formatCurrency(num) {
      num = num.toString().replace(/\$|\,/g, '');
      if (isNaN(num))
        num = "0";
      let sign = (num == (num = Math.abs(num)));
      num = Math.floor(num * 100 + 0.50000000001);
      let cents = num % 100;
      num = Math.floor(num / 100).toString();
      if (cents < 10)
        cents = "0" + cents;
      for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
        num = num.substring(0, num.length - (4 * i + 3)) + ',' +
          num.substring(num.length - (4 * i + 3));
      return (((sign) ? '' : '-') + num + '.' + cents);
    },
    lilvCal: function (year, lilv, selectcontent) {
      var getLilv = "",
        lilvbindArray = [];
      lilv.forEach(function (item, index) {
        if (year >= item.year) {
          getLilv = item.lilv;
        }
      });
      var callilv = selectcontent.map(function (item, index) {
        return {
          lilv: (item.lilv * getLilv).toFixed(4),
          name: item.name
        }
      });
      return callilv
    },
  },
  filters:{
    formatCurrencyFilter(num){
      return this.formatCurrency(num)
    }
  }
}
</script>

<style scoped lang="scss">
  .calculator__body /deep/{
    .body__column--box{
      background: none;
    }
  }
</style>
