<template>
  <div class="sell__body">
    <div class="trusContain" style="padding-top: 72px;">
      <div class="leftmodule">
        <h3>{{$t('about.contact.sellTitle')}}</h3>
        <div class="info" v-html="$t('blog.sale.content')"></div>
        <div>
          <h4>{{$t('about.contact.title')}}</h4>
          <div class="form-module" style="width:526px; float: left;height: 524px;">
            <!-- <form @submit.prevent="simulateSubmit" style="position: relative;">
              <q-input outlined v-model="contact.userName" style="width: 270px; float: left;margin-bottom:30px;" :label="$t('about.contact.Name')" />
              <q-input outlined v-model="contact.email" style="width: 270px; float: right;margin-bottom:30px;" :label="$t('about.contact.Email')" />
              <q-input outlined v-model="contact.mobile"  style="width: 270px; float: left;margin-bottom:30px;"  :label="$t('about.contact.Phone')" />
              <q-input outlined v-model="contact.weixinNo"  style="width: 270px; float: right;margin-bottom:30px;" :label="$t('about.contact.Wechat')" />
              <q-input class="mt30" style="width: 100%;" outlined v-model="contact.address" :label="$t('about.contact.address')" />
              <q-input type="textarea" class="mt30" style="width: 100%;" outlined v-model="contact.question" :label="$t('about.contact.questions')" />
              <q-btn :loading="submitting" class="submit-btn" :align="center" size="sm" color="primary"  type="submit" :label="$t('about.contact.Send')">
                <template v-slot:loading>
                  <q-spinner-facebook />
                </template>
              </q-btn>
            </form> -->
            


            <el-form :model="contact" :inline="true" :rules="rules" ref="ruleForm" label-width="" class="demo-ruleForm">
              <el-form-item label="" prop="userName">
                <el-input class="common-input" :placeholder="$t('about.contact.Name')" prefix-icon="el-icon-user" v-model="contact.userName"></el-input>
              </el-form-item>
              <el-form-item label="" prop="email">
                <el-input class="common-input" :placeholder="$t('about.contact.Email')" prefix-icon="el-icon-message" v-model="contact.email"></el-input>
              </el-form-item>
              <el-form-item label="" prop="mobile">
                <el-input class="common-input" :placeholder="$t('about.contact.Phone')" prefix-icon="el-icon-mobile-phone" v-model="contact.mobile"></el-input>
              </el-form-item>
              <el-form-item label="" prop="weixinNo">
                <el-input class="common-input" :placeholder="$t('about.contact.Wechat')" prefix-icon="el-icon-chat-line-round" v-model="contact.weixinNo"></el-input>
              </el-form-item>
              <el-form-item label="" prop="address" class="oneLine">
                <el-input :placeholder="$t('about.contact.address')" prefix-icon="el-icon-location-outline" v-model="contact.address"></el-input>
              </el-form-item>
              <el-form-item label="" prop="question" class="oneLine">
                <el-input type="textarea" :placeholder="$t('about.contact.questions')" v-model="contact.question"></el-input>
              </el-form-item>
            </el-form>
            <div class="form-btn">
              <el-button @click="simulateSubmit('ruleForm')" :loading="submitting" class="submit-btn" type="primary">{{$t('about.contact.Send')}}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="rightmodule"></div>
    </div>    
  </div>
</template>

<script>
export default {
  name: 'Sell',
  data(){
    return {
      rules: {
        userName: [
          { required: true, message: this.$t('about.contact.Name'), trigger: 'blur' }
        ],
        email: [
          { required: true, message: this.$t('about.contact.Email'), trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: this.$t('about.contact.Phone'), trigger: 'blur' }
        ],
        address: [
          { required: true, message: this.$t('about.contact.address'), trigger: 'blur' }
        ],
      },
      contact:{
        type:2,
        userName:"",
        mobile:"",
        email:"",
        weixinNo:"",
        question:"",
        address:""
      },
      submitting:false,
      typeOptions:[
        {
          label:this.$t('nav.SELL'),
          value:2
        },
        {
          label: this.$t('nav.TRUSTEESHIP'),
          value: 3
        }
      ]
    }
  },
  methods:{
    async simulateSubmit(formName){
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.submitting = true;
          try {
            let res = await this.$api.requestSendTouch(this.contact);
            this.submitting = false;
            if(res.code !== 1000){
              return false;
            }
            let initialData = this.$options.data();
            let contact = initialData.contact;
            this.contact = contact;
            this.$q.notify({
              message:this.$t("about.contact.SendSuccess"),
              position:"top"
            })
          }catch (e) {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });    
    }
  }
};
</script>

<style lang="scss" scoped>
  .form-btn{
    width: 100%;
    text-align: center;
    position: relative;
  }
  .oneLine{
    width: 100%;
    display: block;
  }
  ::v-deep.oneLine .el-form-item__content{
    width: 100%;
  }
  .common-input{
    width: 253px;
  }
  ::v-deep.el-form--inline .el-form-item:nth-child(2n){
    margin-right: 0;
  }
  ::v-deep.el-form--inline .el-form-item:nth-child(2n+1){
    margin-right: 20;
  }
  .sell__body{
    overflow: hidden;
  }
  .trusContain{
    width: 1120px;
    margin:50px auto;
    overflow: hidden;
    padding: 55px 40px;
    background-color: #fff;
    box-sizing: content-box;
    .leftmodule{
      width: 640px;
      float: left;
      h3{
        color: #707070;
        font-size: 28px;
        height: 50px;
        line-height: 50px;
        padding-bottom: 15px;
      }
      h4{
        color: #443227;
        font-size: 20px;
        height: 35px;
        line-height: 35px;
        margin-bottom: 15px;
      }
      .info{
        font-size: 16px;
        padding-bottom: 44px;
        .sticky__content{
          color: #707070;
          line-height: 28px;
        }
      }
      .form-module{
        padding: 22px;
        box-sizing: content-box;
        border: solid 1px #E5E5E5;
        width: 594px;
        height: 540px
      }
    }
    .rightmodule{
      width: 467px;
      height: 800px;
      float: right;
      background: url('../assets/images/home/new03.jpg') no-repeat center top;
      background-size: cover;
      border-radius: 240px 240px 0 0;

    }
    .submit-btn{
      margin: 50px auto;
      background-color: #1871FF;
      width: 200px;
      color: #fff;
      text-align: center;
      border-radius: 8px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>
<style>
  .q-btn__wrapper{
    padding: 0;
  }
  .mt30{
    margin-top: 30px;
  }
</style>
